import "flag-icons"

export default ({data, changeLang}) => {
    console.log(data["First name"])

    return (
        <div className="container">
            <div className="row">
                <div className="col-md-6 offset-md-3">
                    <div className="intro-header">
                        <img src="https://lbp.net/wp-content/themes/lbp/images/logo.png" />
                    </div>
                    <div className="intro">
                        <p>Hello {data["firstname"]},</p>
                        <p>Select your language below to fill in the agreement</p>
                        <ul className="language-selector">
                            <li className="language-selector-option" onClick={() => changeLang("nl")}><><span className="fi fi-nl "></span> Nederlands</></li>
                            <li className="language-selector-option" onClick={() => changeLang("en")}><><span className="fi fi-gb "></span> English</></li>
                            <li className="language-selector-option" onClick={() => changeLang("pl")}><><span className="fi fi-pl "></span> Polski</></li>
                            <li className="language-selector-option" onClick={() => changeLang("ua")}><><span className="fi fi-ua "></span> українська</></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}