import React from "react";
import ReactDOM from "react-dom/client";
import "./index.scss";
import App from "./App";
import "bootstrap";
import HygieneAgreement from "./HygieneAgreement";

const root = ReactDOM.createRoot(document.getElementById("root"));
const path = window.location.pathname;

console.log(path);

root.render(
  <React.StrictMode>
    {path === "/hygiene-agreement" ? 
      <HygieneAgreement /> 
    : 
      <App />
    }
  </React.StrictMode>
);
