import { useEffect, useState, Fragment, useCallback } from "react";
import { useDropzone } from "react-dropzone";
import { useForm } from "react-hook-form";
import ShowStatus from "./ShowStatus";

export default function FileUpload({ data, token }) {
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone();

  const [status, setStatus] = useState()

  function uploadFile() {
    acceptedFiles.map(file => {
        let formData = new FormData();
        formData.append("file", file);
    
        fetch(
          `https://api.lbp.net/questionaire/file-upload?key=${data.id}&token=${token}`,
          {
            method: "POST",
            body: formData
          }
        ).then(res => {
          if(res.ok){
            setStatus('success')
            return res.json()
          } else {
            throw Error('Error while uploading files, try again.');
          }
        })
        .catch(function(error) {
          setStatus('error')
          console.log(error);
      });
    })
    
  }

  const files = acceptedFiles.map((file) => (
    <li key={file.path}>{file.path}</li>
  ));

  return (
    <>
      <section className="container file-upload">
        <div className="row">
          <div className="col-md-4 offset-md-4">
            <h3>Upload document</h3>
            <div {...getRootProps({ className: "dropzone" })}>
              <input {...getInputProps()} />
              <p>Drag 'n' drop some files here, or click to select files</p>
            </div>
            <aside>
              <h4>Files selected to upload</h4>
              <ul>{files}</ul>
            </aside>
            <button type="submit" onClick={uploadFile} className="btn btn-primary">
              Submit
            </button>
            {status === "success" ? <div className="alert alert-success" role="alert"> upload successfull </div> : null}
            {status === "error" ? <div className="alert alert-danger" role="alert">something went wrong. Reload the page and try again.</div> : null}
          </div>
        </div>
      </section>
    </>
  );
}
