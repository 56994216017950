import { useEffect, useState, Fragment } from "react";
import { useForm } from "react-hook-form";
import ReactMarkdown from "react-markdown";
import FileUpload from "./FileUpload";
import ShowStatus from "./ShowStatus";

export default function App() {
  const [form, setForm] = useState(false);

  const params = new URLSearchParams(window.location.search);

  const token = params.get("token");

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    console.log(Object.entries(errors).length, errors);
    if (Object.entries(errors).length === 0) {
      submitForm(data);
    }
  };

  useEffect(() => {
    fetch("https://api.lbp.net/questionaire/get?token=" + token, {
      method: "GET",
    })
      .then((res) => {
        if (!res.ok) {
          return Promise.reject();
        }
        return res.json();
      })
      .then((res) => {
        setForm(res);
      })
      .catch((error) => {
        console.log(error);
        setForm({ status: "error" });
      });
  }, []);

  function submitForm(data) {
    fetch("https://api.lbp.net/questionaire/submit?token=" + token, {
      method: ["POST"],
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw new Error("Something went wrong");
        }
      })
      .then((res) => {
        console.log("tadam");
        setForm((form) => ({ ...form, upload_file: true }));
      })
      .catch((e) => {
        console.log(e);
      });
  }

  if (form === false) {
    return <ShowStatus loading={true} />;
  }

  if (form.data && form.data.status === 2) {
    return <ShowStatus message="Form already submitted" loading={false} />;
  }

  if (form.status === "disabled") {
    return (
      <ShowStatus
        message="Form is expired. Request a new form."
        loading={false}
      />
    );
  }

  if (form.status === "error") {
    return <ShowStatus message="Form not found" loading={false} />;
  }

  return (
    <>
      <header className="container">
        <h1 className="title">{form.title ? form.title : form.form.title}</h1>
        <img className="logo" src={form.logo ? form.logo : form.form.logo} />
      </header>
      {form.upload_file || form.status === "submitted" ? (
        <>
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-4">
                <p>
                  Please download the file through the download button. Sign it
                  and upload in the uploader below.
                </p>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-md-2">
                {form.pdf_to_download ? (
                  <a
                    href={`https://api.lbp.net/questionaire/get-pdf?file=${form.pdf_to_download}`}
                    target="_blank"
                    className="btn btn-success"
                  >
                    download document
                  </a>
                ) : (
                  <a
                    href={`https://api.lbp.net/questionaire/generate-pdf?token=${token}`}
                    target="_blank"
                    className="btn btn-success"
                  >
                    download document
                  </a>
                )}
              </div>
            </div>
          </div>
          <FileUpload data={form.data ? form.data : form} token={token} />
        </>
      ) : form.sections ? (
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="container">
            <div className="row fields">
              <div className="alert alert-success" role="alert">
                <h4 class="alert-heading">Step 1</h4>
                <p>
                  Fill in all the open fields. After a submitting a draft
                  version of the agreement will be generated with your details.
                </p>
                <p>
                  In the next screen you can download the agreement, sign it by
                  pen and upload it again.
                </p>
              </div>
              {form.sections.map((section, key) => {
                return (
                  <Fragment key={"b" + key}>
                    <h4>{section.name}</h4>
                    <p>{section.description}</p>
                    <div className="col-md-12 markdown-text">
                      <ReactMarkdown children={section.markdown} />
                    </div>
                    {section.fields &&
                      section.fields.map((field, key) => {
                        if (field.type === "text") {
                          return (
                            <div className={`${field.classes}`} key={"a" + key}>
                              <div className="field-block">
                                <div className="row align-items-end">
                                  <div className="col-md-4">
                                    <label
                                      htmlFor={field.id}
                                      className="col-form-label"
                                    >
                                      {field.name}
                                    </label>
                                  </div>
                                  <div className="col-md-8">
                                    <input
                                      type={field.type}
                                      id={field.id}
                                      {...register(field.id, {
                                        required: false,
                                      })}
                                      className="form-control"
                                      aria-describedby={field.id}
                                    />
                                  </div>
                                  <div>
                                    <span id={field.id} className="form-text">
                                      {field.info}
                                    </span>
                                  </div>
                                </div>
                              </div>
                              {errors[field.id] && (
                                <p className="error">This field is required</p>
                              )}
                            </div>
                          );
                        } else if (field.type === "checkbox") {
                          return (
                            <div className={field.classes} key={"a" + key}>
                              <div className="row align-items-end">
                                <div className="col-auto">
                                  <label
                                    htmlFor="inputPassword6"
                                    className="form-check-label"
                                  >
                                    {field.name}
                                  </label>
                                </div>
                                <div className="col-auto">
                                  <input
                                    type={field.type}
                                    {...register(field.id, { required: false })}
                                    id="inputPassword6"
                                    className="form-check-input"
                                    aria-describedby={field.id}
                                  />
                                </div>
                                <div>
                                  <span id={field.id} className="form-text">
                                    {field.info}
                                  </span>
                                </div>
                              </div>
                            </div>
                          );
                        }
                      })}
                  </Fragment>
                );
              })}
            </div>
            <input
              className="btn btn-primary"
              type="submit"
              value="Submit form"
            />
          </div>
        </form>
      ) : null}
    </>
  );
}
