
import { useRef, useState } from 'react';
import SignatureCanvas from 'react-signature-canvas'

export default function({makeSignature}){
    const [saved, setSaved] = useState(false);
    let signPad = useRef({});
    let data = "";

    const clear = () => {
        signPad.current.clear();
      };
      const save = () => {
        data = signPad.current.getTrimmedCanvas().toDataURL("image/png");
        makeSignature(data)
      };
      const show = () => {
        signPad.current.fromDataURL(data);
      };

    console.log(data);

    return (
        <div className="p-10 flex flex-col space-y-24 font-serif justify-center items-center">
        <hr/>
        <div className="  w-56 mt-10   ">
          <h3>Sign below</h3>
          <div className="flex justify-center items-center">
            <SignatureCanvas
              canvasProps={{ width: 500, height: 200, className: "sigCanvas" }}
              className="border-2 border-black "
              ref={signPad}
            />
          </div>
        </div>
        <div className="flex space-x-4 -mt-24">
          {" "}
          <span className={`btn btn-sm btn-warning`} onClick={clear}>Clear</span>
          <span className='btn btn-sm btn-success' onClick={save}>Save</span>
        </div>
      </div>
    )
}



