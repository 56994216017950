import { useEffect, useState, Fragment } from "react";
import { useForm } from "react-hook-form";
import ReactMarkdown from "react-markdown";
import ChoseLanguage from "./ChoseLanguage";
import Drawer from "./Drawer";
import FileUpload from "./FileUpload";
import ShowStatus from "./ShowStatus";

export default function App() {
  const [form, setForm] = useState(false);
  const [lang, setLang] = useState("");
  const [signature, setSignature] = useState("");
  const [success, setSuccess] = useState(false);

  const params = new URLSearchParams(window.location.search);

  const id = params.get("id");
  const userid = params.get("userid");
  const type = params.get("type");

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    console.log(Object.entries(errors).length, errors);
    if (Object.entries(errors).length === 0) {
      submitForm(data);
    }
  };

  function changeLang(lang) {
    setLang(lang);
  }

  useEffect(() => {
    fetch(
      "https://api.lbp.net/hygiene-agreement/get?id=" + id + "&type=" + type + "&lang=" + lang,
      {
        method: "GET",
      }
    )
      .then((res) => {
        if (!res.ok) {
          return Promise.reject();
        }
        return res.json();
      })
      .then((res) => {
        setForm(res);
      })
      .catch((error) => {
        console.log(error);
        setForm({ status: "error" });
      });
  }, [lang]);

  useEffect(() => {
    window.scrollTo(0, document.body.scrollHeight);
  }, [signature]);


  function submitForm(data) {
    fetch(
      "https://api.lbp.net/hygiene-agreement/submit?id=" +
        id +
        "&lang=" +
        lang +
        "&userid=" +
        userid +
        "&type=" + 
        type,
      {
        method: ["POST"],
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(signature),
      }
    )
      .then((res) => {
        if (res.ok) {
          setSuccess(true)
        } else {
          throw new Error("Something went wrong");
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }

  if (form === false) {
    return <ShowStatus loading={true} />;
  }

  if (form.status === "submitted") {
    return <ShowStatus message="Form already submitted" loading={false} />;
  }

  if (!form.title) {
    return (
      <>
        <ChoseLanguage data={form} changeLang={(lang) => setLang(lang)} />
      </>
    );
  }

  return (
    <>
      <header className="container">
        <h1 className="title">{form.title ? form.title : form.form.title}</h1>
        <img className="logo" src={form.logo ? form.logo : form.form.logo} />
      </header>
      {form.upload_file || form.status === "submitted" ? (
        <>
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-4">
                <p>
                  Please download the file through the download button. Sign it
                  and upload in the uploader below.
                </p>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-md-2">
                {form.pdf_to_download ? (
                  <a
                    href={`https://api.lbp.net/questionaire/get-pdf?file=${form.pdf_to_download}`}
                    target="_blank"
                    className="btn btn-success"
                  >
                    download document
                  </a>
                ) : (
                  <a
                    href={`https://api.lbp.net/questionaire/generate-pdf?token=${id}`}
                    target="_blank"
                    className="btn btn-success"
                  >
                    download document
                  </a>
                )}
              </div>
            </div>
          </div>
          <FileUpload data={form.data ? form.data : form} />
        </>
      ) : form.sections ? (
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="container">
            <div className="row fields">
              {form.sections.map((section, key) => {
                return (
                  <Fragment key={"b" + key}>
                    <h4>{section.name}</h4>
                    <p>{section.description}</p>
                    <div className="col-md-12 markdown-text">
                      <ReactMarkdown children={section.markdown} />
                    </div>
                    {section.fields &&
                      section.fields.map((field, key) => {
                        if (field.type === "text") {
                          return (
                            <div className={`${field.classes}`} key={"a" + key}>
                              <div className="field-block">
                                <div className="row align-items-end">
                                  <div className="col-md-4">
                                    <label
                                      htmlFor={field.id}
                                      className="col-form-label"
                                    >
                                      {field.name}
                                    </label>
                                  </div>
                                  <div className="col-md-8">
                                    <input
                                      type={field.type}
                                      id={field.id}
                                      {...register(field.id, {
                                        required: false,
                                      })}
                                      className="form-control"
                                      aria-describedby={field.id}
                                    />
                                  </div>
                                  <div>
                                    <span id={field.id} className="form-text">
                                      {field.info}
                                    </span>
                                  </div>
                                </div>
                              </div>
                              {errors[field.id] && (
                                <p className="error">This field is required</p>
                              )}
                            </div>
                          );
                        } else if (field.type === "checkbox") {
                          return (
                            <div className={field.classes} key={"a" + key}>
                              <div className="row align-items-end">
                                <div className="col-auto">
                                  <label
                                    htmlFor="inputPassword6"
                                    className="form-check-label"
                                  >
                                    {field.name}
                                  </label>
                                </div>
                                <div className="col-auto">
                                  <input
                                    type={field.type}
                                    {...register(field.id, { required: false })}
                                    id="inputPassword6"
                                    className="form-check-input"
                                    aria-describedby={field.id}
                                  />
                                </div>
                                <div>
                                  <span id={field.id} className="form-text">
                                    {field.info}
                                  </span>
                                </div>
                              </div>
                            </div>
                          );
                        } else if (field.type === "prefilled") {
                          return (
                            <div className={field.classes} key={"a" + key}>
                              <div className="row align-items-end">
                                <div className="col-auto">
                                  <label
                                    htmlFor="inputPassword6"
                                    className="form-check-label"
                                  >
                                    {field.name}
                                  </label>
                                </div>
                                <div className="col-auto">
                                  <p>
                                    {field.value
                                      ? field.value
                                      : form.data[field.id]}
                                  </p>
                                </div>
                                <div>
                                  <span id={field.id} className="form-text">
                                    {field.info}
                                  </span>
                                </div>
                              </div>
                            </div>
                          );
                        } else if (field.type === "date") {
                          return (
                            <div className={field.classes} key={"a" + key}>
                              <div className="row align-items-end">
                                <div className="col-auto">
                                  <label
                                    htmlFor="inputPassword6"
                                    className="form-check-label"
                                  >
                                    {field.name}
                                  </label>
                                </div>
                                <div className="col-auto">
                                  <p>
                                    {("0" + new Date().getDate()).slice(-2) +
                                      "-" +
                                      ("0" + (new Date().getMonth() + 1)).slice(
                                        -2
                                      ) +
                                      "-" +
                                      new Date().getFullYear()}
                                  </p>
                                </div>
                                <div>
                                  <span id={field.id} className="form-text">
                                    {field.info}
                                  </span>
                                </div>
                              </div>
                            </div>
                          );
                        }
                      })}
                  </Fragment>
                );
              })}
            </div>

            <Drawer makeSignature={(sign) => setSignature(sign)} />
            <hr />
            {signature ? (
              <input
                className={`btn btn-primary`}
                type="submit"
                value="Submit form"
              />
            ) : null}

            {success ? <span>Upload successfull</span> : null}
          </div>
        </form>
      ) : null}
    </>
  );
}
